
































































































































































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { Comissoes, Indicador, Saque } from '@/plugins/api';
import { eventOff, eventOn } from 'vue-context/src/js/utils';
import { BTable, BvTableCtxObject } from 'bootstrap-vue';
import { columns } from './tabela';
import get from 'bootstrap-vue/src/utils/get';
import ConfirmarAdesao from './ConfirmarAdesao.vue';
import ComissoesContext from './ContextMenu.vue';
import ComissoesExportar from './Exportar.vue';

@Component({
  components: {
    ConfirmarAdesao,
    ComissoesExportar,
    ComissoesContext,
  },
})
export default class ComissoesTable extends Vue {
  @Prop(Number) public readonly indicadorId: number | undefined;
  @Prop(Number) public readonly saqueId: number | undefined;
  @Prop(String) public readonly title: string | undefined;
  @Prop(Boolean) public readonly confirmacao!: boolean;
  @Ref() public readonly table!: BTable;
  @Ref() public readonly context!: ComissoesContext;

  public columns = columns;
  public soma = {} as IComissao;
  public getField = get;

  public loading = false;
  public currentPage = 1;
  public totalRows = 1;
  public perPage = 50;

  get filtros(): IFiltroValues[] {
    return this.$store.getters.filtrosSelecionados;
  }

  get mode(): string {
    if (this.indicadorId !== undefined) {
      return 'indicador';
    }
    if (this.saqueId !== undefined) {
      return 'saque';
    }
    return 'geral';
  }

  created(): void {
    const remove = [] as string[];
    if (this.confirmacao !== true) {
      remove.push('situacao');
    }
    if (remove.length > 0) {
      this.columns = this.columns.filter((column) => {
        return !remove.includes(column.key);
      });
    }
  }

  mounted(): void {
    eventOn(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$on('filtros', this.refresh);
  }

  destroy(): void {
    eventOff(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$off('filtros', this.refresh);
  }

  provider(
    context: BvTableCtxObject,
    callback: (data: IComissao[]) => void
  ): void {
    this.loading = true;

    const request: IRequestOptions = {
      params: {
        page: context.currentPage,
        size: context.perPage,
        sortBy: context.sortBy,
        sortDesc: context.sortDesc ? 'DESC' : 'ASC',
        filters: this.mode === 'geral' ? this.filtros : [],
      },
    };

    let comissoes: Promise<GetComissoesResponse>;
    if (this.indicadorId !== undefined) {
      comissoes = Indicador.getComissoes(this.indicadorId, request);
    } else if (this.saqueId !== undefined) {
      comissoes = Saque.getComissoes(this.saqueId, request);
    } else {
      comissoes = Comissoes.get(request);
    }

    comissoes
      .then((response: GetComissoesResponse) => {
        this.totalRows = response.metadata.total;

        const comissoes = response.data;
        comissoes.forEach((comissao) => {
          comissao._rowVariant = Vue.filter('ComissaoCor')(comissao.situacao);
        });

        this.soma = comissoes.reduce((result, item) => {
          const current = JSON.parse(JSON.stringify(result ?? {})) as IComissao;

          current.valor =
            parseFloat(current.valor.toString() ?? '0') +
            parseFloat(item.valor.toString() ?? '0');

          current.simulacoes.valor_adesao =
            parseFloat(current?.simulacoes?.valor_adesao?.toString() ?? '0') +
            parseFloat(item?.simulacoes?.valor_adesao?.toString() ?? '0');

          return current;
        });
        callback(comissoes);
      })
      .catch(() => {
        callback([]);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public refresh(): void {
    this.table.refresh();
  }

  public clearSelection(): void {
    this.table.clearSelected();
  }

  public selectSituacao(value: string): void {
    const coluna = 'comissoes.situacao';
    if (value !== '') {
      const filtro: IFiltroValues = {
        field: coluna,
        operator: 'eq',
        value: value,
      };
      this.$store.commit('ADD_FILTRO', filtro);
    } else {
      this.$store.commit('REMOVE_FILTRO', coluna);
    }
  }

  public contextMenu(item: IComissao, data: number, event: MouseEvent): void {
    event.preventDefault();
    this.table.selectRow(data);
    this.context.open(event, item);
  }

  public faturaNome(faturas: IFatura[]): string {
    const paga = faturas?.find((fatura) => fatura.situacao === 'paid');
    return paga?.nome ?? '';
  }
}
