import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Main from '@/layouts/Main.vue';
import Home from '@/views/app/Home/Index.vue';
import Login from '@/views/auth/Login.vue';
import Auth from '../plugins/api/Auth';

Vue.use(VueRouter);

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Dashboard',
      description: 'Bem-vindo ao Dashboard Financeiro',
      sidebar: {
        icon: 'mdi mdi-home',
      },
    },
  },
  {
    path: '/faturamentos',
    name: 'Faturamentos',
    meta: {
      title: 'Faturamentos',
      description: 'Lista de Faturamentos emitidos no Moskit',
      sidebar: {
        icon: 'mdi mdi-receipt',
      },
    },
    component: () =>
      import(/* webpackChunkName: "faturamentos" */ '@/views/app/Faturamentos/Index.vue'),
  },
  {
    path: '/faturamentos/:id',
    name: 'Faturamento',
    meta: {
      title: 'Informação de Faturamento',
      description: 'Detalhes de Faturamento relacionado ao Lead do Moskit',
      root: '/faturamentos',
      rootName: 'Faturamentos',
    },
    component: () =>
      import(/* webpackChunkName: "faturamentos" */ '@/views/app/Faturamentos/Faturas.vue'),
  },
  {
    path: '/comissoes',
    name: 'Comissoes',
    meta: {
      title: 'Comissões geradas',
      description: 'Lista de Comissões Geradas',
      sidebar: {
        icon: 'mdi mdi-card-account-details',
      },
    },
    component: () => import(/* webpackChunkName: "comissoes" */ '@/views/app/Comissoes/Index.vue'),
  },
  {
    path: '/saques',
    name: 'Saques',
    meta: {
      title: 'Saques',
      description: 'Lista de Saques',
      sidebar: {
        icon: 'mdi mdi-account-cash',
      },
    },
    component: () => import(/* webpackChunkName: "saques" */ '@/views/app/Saques/Index.vue'),
  },
  {
    path: '/saques/:id',
    name: 'Saque',
    meta: {
      title: 'Saque',
      description: 'Detalhes sobre o Saque',
      root: '/saques',
      rootName: 'Saques',
    },
    component: () => import(/* webpackChunkName: "saques" */ '@/views/app/Saques/Saque.vue'),
  },
  {
    path: '/indicadores',
    name: 'Indicadores',
    meta: {
      title: 'Indicadores',
      description: 'Lista de Indicadores',
      sidebar: {
        icon: 'mdi mdi-account-tie',
      },
    },
    component: () =>
      import(/* webpackChunkName: "indicadores" */ '@/views/app/Indicadores/Index.vue'),
  },
  {
    path: '/indicadores/:id',
    name: 'Indicador',
    meta: {
      title: 'Indicador',
      description: 'Informações do Indicador',
      root: '/indicadores',
      rootName: 'Indicadores',
    },
    component: () =>
      import(/* webpackChunkName: "indicadores" */ '@/views/app/Indicadores/Indicador.vue'),
  },
  {
    path: '/dados-bancarios',
    name: 'DadosBancariosIndex',
    meta: {
      title: 'Alterações Banco',
      description: 'Lista de Pedidos de alteração de dados bancários',
      sidebar: {
        icon: 'mdi mdi-credit-card-plus',
      },
    },
    component: () =>
      import(/* webpackChunkName: "dados-bancarios" */ '@/views/app/DadosBancarios/Index.vue'),
  },
];

const routes = [
  {
    path: '',
    component: Main,
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      description: 'Bem-vindo ao Dashboard Financeiro',
    },
    children: dashboardRoutes,
    beforeEnter: (to, from, next) => {
      if (Auth.instance.isLogged) {
        next();
      } else {
        if (to.name === 'Dashboard') {
          next('/login');
        } else {
          next('/login?invalid');
        }
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login',
      description: 'Login',
    },
    component: Login,
  },
] as RouteConfig[];

const router = new VueRouter({
  routes,
});

export { router as default, routes, dashboardRoutes };
